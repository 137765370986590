<template>
  <v-chip
    class="mx-2 my-2 text-uppercase"
    label
    :color="getStateColor(item)"
  >
    {{ getStateLabel(item) }}
  </v-chip>
</template>

<script>
export default {
  name: 'WorkflowStateLabel',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStateLabel(item) {
      if (item.to.toLowerCase() === '') {
        return '-'
      }

      return this.$i18n.t('state.product.' + item.to.toLowerCase() + '.label')
    },
    getStateColor(item) {
      if (item.to.toLowerCase() === '') {
        return 'grey'
      }

      return this.$i18n.t('state.product.' + item.to.toLowerCase() + '.color')
    },
  },
}
</script>
