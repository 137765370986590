<template>
  <Page>
    <template v-slot:content>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet
            outlined
            rounded
            elevation="3"
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="params.email"
                    label="E-mail"
                    hide-details
                    prepend-inner-icon="mdi-at"
                    dense
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row
                class="pb-2"
                dense
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="params['states[]']"
                    hide-details
                    prepend-inner-icon="mdi-state-machine"
                    dense
                    outlined
                    clearable
                    multiple
                    item-text="label"
                    item-value="value"
                    :items="values.state"
                    :label="$t('views.curation.filters.state')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu.datetime_before"
                    :close-on-content-click="false"
                    :return-value.sync="params.dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="params.dates"
                        label="Date(s)"
                        prepend-inner-icon="mdi-calendar"
                        hide-details
                        dense
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="params.dates"
                      no-title
                      range
                      scrollable
                    >
                      <v-btn
                        text
                        color="primary"
                        @click="menu.datetime_before = false"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        outlined
                        @click="$refs.menu.save(params.dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider />
              <v-sheet rounded>
                <v-data-table
                  :loading="table.loading"
                  :items="items"
                  :headers="headers"
                  hide-default-footer
                  disable-pagination
                  group-by="email"
                  show-group-by
                >
                  <template v-slot:item.to="{ item }">
                    <WorkflowStateLabel :item="item" />
                  </template>
                </v-data-table>
              </v-sheet>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import WorkflowStateLabel from '@/components/core/WorkflowStateLabel'

export default {
  name: 'Dashboard',
  components: { Page, WorkflowStateLabel },
  data() {
    return {
      menu: {
        datetime_before: null,
      },
      table: {
        loading: false,
      },
      params: {
        email: null,
        'states[]': null,
        dates: [],
      },
      values: {
        state: [
          {
            label: this.$i18n.t('views.products.enum.state.pending_internal_review'),
            value: 'pending_internal_review',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR', 'ROLE_EXTERNAL_MODERATOR'],
          },
          {
            label: this.$i18n.t('views.products.enum.state.in_internal_review'),
            value: 'in_internal_review',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR'],
          },
          {
            label: this.$i18n.t('views.products.enum.state.rejected'),
            value: 'rejected',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR', 'ROLE_EXTERNAL_MODERATOR'],
          },
          {
            label: this.$i18n.t('views.products.enum.state.pending_changes'),
            value: 'pending_changes',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR', 'ROLE_EXTERNAL_MODERATOR'],
          },
          {
            label: this.$i18n.t('views.products.enum.state.pending_ready'),
            value: 'pending_ready',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR'],
          },
          {
            label: this.$i18n.t('views.products.enum.state.ready'),
            value: 'ready',
            roles: ['ROLE_ADMINISTRATOR', 'ROLE_INTERNAL_MODERATOR'],
          },
        ],
      },
      headers: [
        {
          text: 'E-mail ',
          value: 'email',
          sortable: false,
          groupable: true,
        },
        {
          text: 'States ',
          value: 'to',
          sortable: false,
          groupable: true,
        },
        {
          text: 'Total ',
          value: 'total',
          sortable: false,
          groupable: false,
        },
      ],
      items: [],
      request: null,
    }
  },
  computed: {
    queryString: function () {
      return 'products/curation/stats?' + getQueryString(1, {
        email : this.params.email ?? null,
        datetime_after : this.params.dates[0] ?? null,
        datetime_before : this.params.dates[1] ?? null,
        'states[]' : this.params['states[]'],
      })
    },
    dates: function () {
      return this.params.dates[0] ?? null + ' ~ ' + this.params.dates[1] ?? null
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.table.loading = true
      this.items = []

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.items = response.data
          this.table.loading = false
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          if (!this.$axios.isCancel(error)) {
            this.table.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
  },
}
</script>

<style scoped>

</style>
